import dayjs from 'dayjs';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  MINUTES_CHECK_IN_AFTER_CLASS,
  MINUTES_CHECK_IN_BEFORE_CLASS,
  WEEK_DAYS
} from 'common/constants/index';
import { convertHexToRgb } from 'common/helpers/calendar.helper';
import ClassDetail from './ClassDetail';
import { formatData } from 'common/helpers/dataFormat.helper';
import { groupBy, some, sortBy } from 'lodash';
import Popup from 'reactjs-popup';
import {
  IGroupClassSession,
  IGroupClassSessionByTime,
  IScheduleClass
} from 'common/interfaces/dashboard.interface';
import AppButton from 'common/components/AppButton';
import { useNavigate } from 'react-router-dom';
import { useDashboardContext } from '../DashboardContext';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

interface Props {
  monthView?: boolean;
}
const today = new Date();
const ScheduleComponent: React.FC<Props> = ({ monthView }: Props) => {
  const navigate = useNavigate();
  const {
    selectedDateForPreview: date,
    selectedTime,
    selectedEndTime,
    selectedLevel,
    classesData = []
  } = useDashboardContext();
  const isToday =
    today.getDate() === date.getDate() && today.getMonth() === date.getMonth();
  const todayClassName = `date-label ${isToday ? 'today' : ''}`;
  const [currentLevel, setCurrentLevel] = useState(selectedLevel);
  const onClickLevel = (item: IGroupClassSession) => {
    setCurrentLevel(item);
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const startXRef = useRef<number>(0);
  const componentWidthRef = useRef<number>(0);
  const [groupLevelsByTime, setGroupLevelsByTime] = useState<
    IGroupClassSessionByTime[]
  >([]);
  const [rollCallIds, setRollCallIds] = useState<Array<string>>([]);

  useEffect(() => {
    const rollCallIds = [] as Array<string>;

    const selectStartTime = dayjs(
      `${dayjs(date).format('YYYY-MM-DD')} ${selectedTime}`
    ).format('YYYY-MM-DD HH:mm');

    const selectEndTime = dayjs(
      `${dayjs(date).format('YYYY-MM-DD')} ${selectedEndTime}`
    ).format('YYYY-MM-DD HH:mm');

    const levels = classesData
      .filter((session: IGroupClassSession) => {
        if (monthView) {
          return (
            dayjs(session.startTime).format('YYYY-MM-DD') ===
            dayjs(date).format('YYYY-MM-DD')
          );
        } else {
          const startTimeSession = dayjs(session.startTime).format(
            'YYYY-MM-DD HH:mm'
          );
          const endTimeSession = dayjs(session.endTime).format(
            'YYYY-MM-DD HH:mm'
          );
          if (session.duration === 30) {
            return (
              startTimeSession === selectStartTime &&
              endTimeSession === selectEndTime
            );
          } else {
            return (
              startTimeSession === selectStartTime ||
              endTimeSession === selectEndTime
            );
          }
        }
      })
      .map((item: IGroupClassSession) => {
        item.schedules.forEach((schedule) => {
          if (schedule.rollCall?._id) {
            rollCallIds.push(schedule.rollCall?._id);
          }
        });

        return {
          ...item,
          timeForGroup: `${dayjs(item.startTime).format('HH:mm')} - ${dayjs(
            item.endTime
          ).format('HH:mm')}`,
          isCanBook: some(
            item.schedules,
            (schedule) => schedule.vacancySession > 0
          )
        };
      });
    setRollCallIds(rollCallIds);
    const groupLevelsByTime = groupBy(levels, 'timeForGroup');
    const dataToDisplay = [] as Array<IGroupClassSessionByTime>;
    Object.keys(groupLevelsByTime).forEach((key: string) => {
      dataToDisplay.push({
        timeForGroup: key,
        classes: sortBy(groupLevelsByTime[key], 'levelBreakdown.shortName')
      });
    });

    setGroupLevelsByTime(sortBy(dataToDisplay, 'timeForGroup'));
  }, [classesData, date, monthView, selectedTime, selectedEndTime]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (componentRef.current && isHovered) {
      startXRef.current = event.clientX;
      componentWidthRef.current = componentRef.current.offsetWidth;

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (componentRef.current) {
      const widthDiff = startXRef.current - event.clientX;
      const newWidth = componentWidthRef.current + widthDiff;

      if (newWidth >= 130) {
        componentRef.current.style.width = `${newWidth}px`;
      }
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (selectedLevel) {
      setCurrentLevel(selectedLevel);
    }
    // eslint-disable-next-line
  }, [selectedLevel]);

  const renderLevelByTime = () => {
    return (
      <>
        {[...groupLevelsByTime].map((data: IGroupClassSessionByTime) => {
          return (
            <div
              style={{ marginBottom: '19px', width: '100%' }}
              key={data.timeForGroup}
            >
              <div className="time-text">
                {data.timeForGroup || dayjs(today).format('HH:mm')}
              </div>
              <div className="schedule-column">
                {data.classes.map(
                  (classItem: IGroupClassSession, index: number) => {
                    return (
                      <Fragment key={index}>
                        <div
                          className="level-item-wrapper"
                          key={data.timeForGroup}
                        >
                          <div
                            className="level-item"
                            style={{
                              borderLeft: `4px solid ${
                                classItem?.colorCode || '#034ea2'
                              }`
                            }}
                            key={classItem?.levelBreakdown?._id}
                            onClick={() => onClickLevel(classItem)}
                          >
                            <div className="text">
                              {`${formatData(
                                classItem.levelBreakdown?.shortName ||
                                  'All Levels'
                              )} `}
                            </div>
                            {classItem.isCanBook && (
                              <div
                                className="active"
                                style={{
                                  backgroundColor:
                                    classItem?.colorCode ||
                                    classItem?.levelBreakdown?.colorCode ||
                                    '#034ea2'
                                }}
                              ></div>
                            )}
                          </div>
                          <div
                            className={`list-class-animation ${
                              currentLevel?._id?.level === classItem?._id?.level
                                ? 'active'
                                : ''
                            }`}
                          >
                            <div className="list-class-wrapper">
                              {classItem.schedules.map(
                                (schedule: IScheduleClass) => {
                                  return (
                                    <div key={schedule?._id}>
                                      <Popup
                                        position="left center"
                                        className="content-tooltip-calendar"
                                        keepTooltipInside={true}
                                        trigger={
                                          <div
                                            className="class-item"
                                            data-tooltip-id={`content-tooltip-class-${schedule?._id}`}
                                            style={{
                                              cursor: 'pointer',
                                              backgroundColor: convertHexToRgb(
                                                classItem?.colorCode ||
                                                  '#034ea2',
                                                0.8
                                              )
                                            }}
                                          >
                                            <div className="class-item-title">
                                              {formatData(
                                                schedule?.instructor?.firstName
                                              )}
                                            </div>
                                            <div className="class-item-description">
                                              Area {formatData(schedule.area)}
                                            </div>
                                            <div className="class-item-description">
                                              Capacity{' '}
                                              {formatData(schedule.capacity)}
                                            </div>
                                            {(schedule.capacity || 0) -
                                              (schedule.occupied || 0) >=
                                            0 ? (
                                              <div className="class-item-description">
                                                Available{' '}
                                                {formatData(
                                                  (schedule.capacity || 0) -
                                                    (schedule.occupied || 0)
                                                )}
                                              </div>
                                            ) : (
                                              <div className="class-item-description">
                                                Overbooked by{' '}
                                                {formatData(
                                                  (schedule.occupied || 0) -
                                                    (schedule.capacity || 0)
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        }
                                      >
                                        <ClassDetail
                                          content={{
                                            ...schedule,
                                            colorCode: classItem.colorCode
                                          }}
                                          level={
                                            classItem.levelBreakdown
                                              ?.shortName || 'All Levels'
                                          }
                                        />
                                      </Popup>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const onRollCall = useCallback(() => {
    navigate(`roll-calls/${rollCallIds.join(',')}`);
  }, [rollCallIds, navigate]);

  const showRollCallBtn = () => {
    if (monthView) return false;

    if (rollCallIds.length === 0) return false;

    const classDate = `${dayjs(date).format('YYYY-MM-DD')} ${selectedTime}`;
    const endTime = dayjs(classDate).add(30, 'minutes');

    const canCheckInRollCall =
      dayjs().diff(dayjs(classDate), 'minute') >=
        -MINUTES_CHECK_IN_BEFORE_CLASS &&
      dayjs().diff(dayjs(endTime), 'minute') <= MINUTES_CHECK_IN_AFTER_CLASS;

    return canCheckInRollCall;
  };

  return (
    <div
      style={{ position: 'relative' }}
      className="schedule-wrapper"
      ref={componentRef}
      onMouseDown={handleMouseDown}
    >
      <div
        className={`border-left ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      ></div>
      <div className={`calendar-wrapper-right`}>
        <div className="date-info">
          <div className="date-info-header">
            <div className="day-label">{WEEK_DAYS[date.getDay()]}</div>
            <div className={todayClassName}>{date.getDate()}</div>
          </div>
        </div>
        <div className="schedule-by-level"> {renderLevelByTime()}</div>
        {showRollCallBtn() && (
          <PermissionWrapper permission={PERMISSION.DETAIL_GROUP_ROLL_CALL}>
            <AppButton
              style={{ width: '100%' }}
              buttonSize="small"
              onClick={onRollCall}
            >
              Roll Call
            </AppButton>
          </PermissionWrapper>
        )}
      </div>
    </div>
  );
};
const Schedule = React.memo(ScheduleComponent);
export default Schedule;
