export enum GENDER_VALUE {
  IDLE = "",
  FEMALE = "female",
  MALE = "male",
  UNISEX = "unisex",
}

export enum STATUS_TYPE_LABEL {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum STATUS_TYPE {
  ACTIVE = "active",
  INACTIVE = "inactive",
  SUSPENDED = "suspended",
  ALL = "all",
}

export enum ENROLLMENT_STEP {
  BOOKING = "1",
  ENROLLMENT = "2",
  PAYMENT = "3",
}

export enum ENROLLMENT_STATUS {
  NOT_ENROLLED = "not_enrolled",
  ACTIVE = "active",
  CASUAL = "casual",
}
